<template>
  <BasicModal
    action-title="Revoke access"
    :action="deleteAccountIntegration"
    :action-button-classes="['danger']"
  >
    <div class="header">
      <ErrorIcon />
      <h4>{{ accountIntegration.provider === 'webhook' ? 'Delete webhook?' : 'Revoke Access?' }}</h4>
    </div>
    <p>
      Are you sure you want to {{ accountIntegration.provider === 'webhook' ? 'delete' : 'revoke access to' }} <strong>{{ accountIntegration.name }}</strong>?
    </p>
    <div v-if="associatedPatches.length !== 0">
      <p class="mt-6">
        <strong>The following {{ associatedPatches.length > 1 ? `${associatedPatches.length} Patches` : 'Patch' }} will be deleted:</strong>
      </p>
      <ul>
        <li
          :key="associatedPatch.id"
          v-for="associatedPatch in associatedPatches"
        >
          {{ associatedPatch.name }}
        </li>
      </ul>
      <br>
      <strong class="text-danger-700">This action is permanent and cannot be undone.</strong>
    </div>
  </BasicModal>
</template>

<script>

  import BasicModal from '@/components/modals/_BasicModal.vue'

  import ErrorIcon from '@/assets/icons/error.svg'

  export default {
    components: {
      ErrorIcon,
      BasicModal,
    },
    props: {
      accountIntegration: {
        type: Object,
        required: true,
      },
      associatedPatches: {
        type: Array,
        default: () => { return [] },
      },
    },
    methods: {
      deleteAccountIntegration() {
        return this.$store.dispatch('api/DELETE_ACCOUNT_INTEGRATION', this.accountIntegration.id)
          .then(() => {
            return Promise.all([
              this.$store.dispatch('user/REFRESH_USER_PLAN_STATS'),
              this.$store.dispatch('user/REFRESH_ACCOUNT_INTEGRATIONS'),
              this.$store.dispatch('pagination/LOAD_PAGE', { listName: 'userPatches' }),
            ])
          })
          .then(() => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: `${this.accountIntegration.provider === 'webhook' ? 'Webhook' : 'Application'} deleted!`,
              type: 'success',
            })
          })
          .catch(() => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: `Could not delete ${this.accountIntegration.provider === 'webhook' ? 'webhook' : 'application'}!`,
              type: 'error',
            })
          })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .header
    @apply mb-6
    @apply space-x-2

    @apply flex
    @apply items-center

    h4
      @apply mb-0

    svg
      @apply w-8
      @apply h-8
      @apply text-danger-700

  ul
    @apply mt-6

</style>
